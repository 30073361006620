<template>
  <div>
    <!-- Start Data Table -->
    <v-data-table
      :headers="bank_accounts_headers"
      :items="bank_accounts"
      :loading="loading"
      loading-text="Loading... Please wait"
      :items-per-page="10"
      class="elevation-0"
    >
      <template v-slot:item.active_status="{ item }">
        <v-chip v-if="item.is_active" color="green" dark> Active </v-chip>

        <v-chip v-else color="red" dark> Inactive </v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="item.is_active"
          color="green"
          dark
          text
          @click="onActiveDeactive(item)"
        >
          Make Inactive
        </v-btn>

        <v-btn v-else color="red" dark text @click="onActiveDeactive(item)">
          Make Active
        </v-btn>
      </template>
    </v-data-table>
    <!-- End Data Table -->

    <!-- Start Snackbar -->
    <v-snackbar v-model="snackbar" color="white" centered>
      <p class="black--text mb-0">{{ text }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
  </div>
</template>

<script>
export default {
  props: ["render"],
  data() {
    return {
      bank_accounts_headers: [
        {
          text: "Holder Name",
          align: "start",
          sortable: false,
          value: "account_holder_name",
          class: "custom-header black--text",
          divider: true,
        },
        {
          text: "Staus",
          value: "active_status",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Account Number",
          value: "account_no",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Bank Name",
          value: "bank_name",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Branch Name",
          value: "branch_name",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Swift Code",
          value: "swift_code",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Address",
          value: "address",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Branch Code",
          value: "branch_code",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "IFSC",
          value: "",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Actions",
          value: "action",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
      ],
      bank_accounts: [],
      loading: true,
      snackbar: false,
      text: "",
    };
  },
  watch: {
    render: function () {
      this.getBankAccounts();
    },
  },
  methods: {
    // get all bank accounts
    getBankAccounts() {
      this.loading = true;
      axios
        .get(`impress/show_bank_accounts`)
        .then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.bank_accounts = response.data.data;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // if active make inactive else make active
    onActiveDeactive(item) {
      // Find the item
      let itemToChange = this.bank_accounts.find(
        (element) => element.id == item.id
      );

      // Make API call
      axios
        .get(`impress/make_account_active_inactive/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            itemToChange.is_active = !itemToChange.is_active;
          } else {
            this.createSnackbar("Something went wrong");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
        });
    },

    // Create snackbar
    createSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
  },
  created() {
    this.getBankAccounts();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>